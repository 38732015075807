<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<ship-form-header :modelPk="modelPk"></ship-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.shipcert.info') }}</v-tab>
				<v-tab v-if="!isCreatingElement && showDocuments" :key="1" :href="'#documents'">{{ $t('pui9.documents.tab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<v-col cols="6">
									<pui-select
										:id="`certtypeid-${modelName}`"
										:attach="`certtypeid-${modelName}`"
										:label="$t('form.shipcert.certtypeid')"
										toplabel
										clearable
										required
										:disabled="formDisabled"
										v-model="model"
										modelName="shipcerttype"
										:modelFormMapping="{ shipcerttypeid: 'certtypeid' }"
										:itemsToSelect="itemsToSelectCertType"
										itemValue="shipcerttypeid"
										itemText="description"
										:order="{ descripcion: 'asc' }"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6">
									<pui-date-field
										:id="`startdate-${modelName}`"
										:label="$t('form.shipcert.startdate')"
										v-model="model.startdate"
										:max="model.enddate"
										toplabel
										required
										:disabled="formDisabled"
									></pui-date-field>
								</v-col>
								<v-col cols="6">
									<pui-date-field
										:id="`enddate-${modelName}`"
										:label="$t('form.shipcert.enddate')"
										v-model="model.enddate"
										:min="model.startdate"
										toplabel
										required
										:disabled="formDisabled"
									></pui-date-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6">
									<pui-number-field
										:id="`registrynumber-${modelName}`"
										:label="$t('form.shipcert.registrynumber')"
										v-model="model.registrynumber"
										toplabel
										:disabled="formDisabled"
									></pui-number-field>
								</v-col>
								<v-col cols="6">
									<pui-date-field
										:id="`registrydate-${modelName}`"
										:label="$t('form.shipcert.registrydate')"
										v-model="model.registrydate"
										toplabel
										:disabled="formDisabled"
									></pui-date-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6" v-if="this.show7">
									<pui-select
										:id="`portid-${modelName}`"
										:attach="`portid-${modelName}`"
										:label="$t('form.shipcert.port')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="port"
										:modelFormMapping="{ id: 'portid' }"
										:itemsToSelect="itemsToSelectPort"
										itemValue="id"
										itemText="portname"
										required
										reactive
										:fixedFilter="filterByPortAuthorityAndPort"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6" v-if="this.show5">
									<pui-number-field
										:id="`averagenumports-${modelName}`"
										:label="$t('form.shipcert.averagenumports')"
										v-model="model.averagenumports"
										toplabel
										required
										:disabled="formDisabled"
									></pui-number-field>
								</v-col>
								<v-col cols="6" v-if="this.show5">
									<pui-select
										:id="`port1-${modelName}`"
										:attach="`port1-${modelName}`"
										:label="$t('form.shipcert.port1')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="worldport"
										:modelFormMapping="{ code: 'port1' }"
										:itemsToSelect="itemsToSelectPort1"
										:itemValue="['code']"
										:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
										required
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6" v-if="this.show5">
									<pui-select
										:id="`port2-${modelName}`"
										:attach="`port2-${modelName}`"
										:label="$t('form.shipcert.port2')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="worldport"
										:modelFormMapping="{ code: 'port2' }"
										:itemsToSelect="itemsToSelectPort2"
										:itemValue="['code']"
										:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="6" v-if="this.show5">
									<pui-select
										:id="`port3-${modelName}`"
										:attach="`port3-${modelName}`"
										:label="$t('form.shipcert.port3')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="worldport"
										:modelFormMapping="{ code: 'port3' }"
										:itemsToSelect="itemsToSelectPort3"
										:itemValue="['code']"
										:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="6" v-if="this.show5">
									<pui-select
										:id="`port4-${modelName}`"
										:attach="`port4-${modelName}`"
										:label="$t('form.shipcert.port4')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="worldport"
										:modelFormMapping="{ code: 'port4' }"
										:itemsToSelect="itemsToSelectPort4"
										:itemValue="['code']"
										:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="6" v-if="this.show3">
									<pui-select
										:id="`wasteplantypeid-${modelName}`"
										:attach="`wasteplantypeid-${modelName}`"
										:label="$t('form.shipcert.wasteplantypeid')"
										toplabel
										clearable
										required
										:disabled="formDisabled"
										v-model="model"
										modelName="wasteplantype"
										:modelFormMapping="{ wasteplantypeid: 'wasteplantypeid' }"
										:itemsToSelect="itemsToSelectWasteType"
										itemValue="wasteplantypeid"
										itemText="description"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">
									<pui-text-area
										:id="`comments-${modelName}`"
										:label="$t('form.shipcert.comments')"
										v-model="model.comments"
										toplabel
										:disabled="formDisabled"
										maxlength="300"
									></pui-text-area>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="`documents`" v-if="!isCreatingElement && showDocuments">
					<pui-master-detail componentName="PuiDocumentGrid" :parentModelName="modelName" :parentPk="pk"></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import shipcertActions from './ShipcertActions';
import ShipFormHeader from '../ship/ShipFormHeader.vue';

export default {
	name: 'shipcert-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		ShipFormHeader
	},
	data() {
		return {
			modelName: 'shipcert',
			actions: shipcertActions.formActions,
			certtype3: 3,
			certtype5: 5,
			certtype7: 7,
			show3: false,
			show5: false,
			show7: false,
			wasteplantype1: 1,
			model: {
				certtypeid: 0
			}
		};
	},
	methods: {
		afterGetData() {
			this.showHide();
			if (this.isCreatingElement) {
				(this.model = {
					certtypeid: 1,
					portauthorityid: window.localStorage.getItem('workingPortAuthorityId'),
					shipid: JSON.parse(atob(this.$attrs.parentpk)).id
				}),
					(this.modelLoaded = true);
			}
		},
		showHide() {
			this.show3 = this.showHide3();
			this.show5 = this.showHide5();
			this.show7 = this.showHide7();
		},
		showHide3() {
			return this.model.certtypeid == this.certtype3 || this.model.certtypeid == this.certtype5;
		},
		showHide5() {
			return this.model.certtypeid == this.certtype5;
		},
		showHide7() {
			return this.model.certtypeid == this.certtype7 || this.model.certtypeid == this.certtype5;
		}
	},
	watch: {
		'model.certtypeid'() {
			this.showHide();
		}
	},
	computed: {
		itemsToSelectCertType() {
			return [{ shipcerttypeid: this.model.certtypeid }];
		},
		itemsToSelectWasteType() {
			if (this.model.certtypeid == this.certtype3) {
				return [{ wasteplantypeid: this.wasteplantype1 }];
			}
			return [{ wasteplantypeid: this.model.wasteplantypeid }];
		},
		itemsToSelectPort() {
			return [{ id: this.model.portid }];
		},
		itemsToSelectPort1() {
			return [{ code: this.model.port1 }];
		},
		itemsToSelectPort2() {
			return [{ code: this.model.port2 }];
		},
		itemsToSelectPort3() {
			return [{ code: this.model.port3 }];
		},
		itemsToSelectPort4() {
			return [{ code: this.model.port4 }];
		},
		filterByPortAuthorityAndPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') },
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds }
				]
			};
		}
	},
	created() {},
	mounted() {}
};
</script>
